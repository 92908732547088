import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import Icon, { IconTypes } from '../../../components/Icon/Icon';
import { useLocaleContext } from '../../../context/Locale';
import { LocaleTag } from '../../../../config/locales';
// import SurveyForm from '../../../components/Deposits/SurveyForm';
// import { getTableRows } from '../../../shared/utils/table';
import Link from '../../../components/Link/Link';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import CommonData from '../../../data/common/common';
import './style.scss';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';

type Props = PageProps & WithTranslation;

const DepositsPromotions: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('promotions.seo.title'),
    },
    // page: {
    //   title: t('promotions.heading'),
    // },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.FOREST_GREEN,
        heroImage: {
          fileName: 'hero-deposits-promo.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const gicEmail = t(`${LocaleNameSpaceKeys.COMMON}:emails.gic`);
  const cdicBrochure = CommonData.websites.cdicBrochure[activeLocaleTag];

  return (
    <Layout options={layoutOptions} className="Deposits Deposits__promotions">
      <div className="grid-container Deposits__generic-gap">
        <div className="row row-wrap Deposits__row-flex">
          <section className="column column-80">
            {/* <div className="Deposits__generic-gap">
              <h2 id="deposits-faq">{t('promotions.headingTwo')}</h2>
              <h4 id="deposits-faq">{t('promotions.headingFour')}</h4>
              <p>
                <Trans t={t} i18nKey="promotions.body" />
              </p>
              <p>{t('promotions.list.heading')}</p>
              <ul>
                {(t('promotions.list.items', { returnObjects: true }) as string[]).map(
                  (listItem, index) => (
                    <li key={index}>
                      <Trans>{listItem}</Trans>
                    </li>
                  )
                )}
              </ul>
              <p>
                <Trans
                  t={t}
                  i18nKey="promotions.bodyTwo"
                  values={{
                    email: gicEmail,
                    phone: CommonData.telephoneNumber.gicTwo,
                  }}
                >
                  <ExternalLink
                    href={`tel:${CommonData.telephoneNumber.gic}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.telephoneNumber.gic
                    }`}
                  />
                  <ExternalLink
                    href={`mailto:${gicEmail} `}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${gicEmail}`}
                  />
                </Trans>
              </p>
            </div> */}
            {/* <div className="Deposits__generic-gap">
              <h4>{t('promotions.table.heading')}</h4>
              <table>
                <tbody>{getTableRows(t('promotions.table.rows', { returnObjects: true }))}</tbody>
              </table>
              <br />
            </div> */}

            <div className="Deposits__generic-gap">
              <h1 className="h2" id="deposits-faq">
                {t('promotions.noPromo.headingTwo')}
              </h1>
              <p>
                <Trans t={t} i18nKey="promotions.noPromo.body">
                  <Link to={getPathFromRouteKey(RouteKeys.DEPOSITS)} />
                </Trans>
              </p>
              {/* <p className="small">
                <Trans t={t} i18nKey="promotions.footerNote" />
              </p> */}
            </div>

            <div className="Deposits__generic-gap">
              <h2 className="h4">{t('deposits.cdicBlock.heading')}</h2>
              <p>
                <Trans t={t} i18nKey="deposits.cdicBlock.body">
                  <ExternalLink href={cdicBrochure} />
                </Trans>
              </p>
              <div className="deposits-cdic-logo">
                <ExternalLink href={CommonData.websites.cdic[activeLocaleTag]}>
                  <div
                    className="cdic-logo-wrapper"
                    aria-label={t(`${LocaleNameSpaceKeys.COMMON}:cdic.name`)}
                  >
                    <Icon
                      type={activeLocaleTag === LocaleTag.EN ? IconTypes.CDIC : IconTypes.SADC}
                    />
                  </div>
                </ExternalLink>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.DEPOSITS)(DepositsPromotions);
